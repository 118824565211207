'use client'

import {
  Button,
  Flex,
  Text,
  Heading,
  Stack,
  Image,
} from '@chakra-ui/react'

import bg from '../assests/bg.jpg'
import logo_wit from '../assests/logo_wit.png'
import { useForm, FormProvider } from 'react-hook-form'
import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../contexts/Auth.context';
import React from 'react';
import Toast from '../components/toast'
import { useMemo } from 'react';
import LabelInput from '../components/Forms'

export default function SplitScreen() {
  const { loading, login } = useAuth();
  const navigate = useNavigate();
  const { search } = useLocation();

  const redirect = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    if (urlParams.has("redirect"))
      return urlParams.get("redirect");
    return "/";
  }, [search]);

  const { addToast } = Toast();

  const methods = useForm();

  const { handleSubmit } = methods;

  const handleLogin = useCallback(
    async ({ username, password}) => {
      const loggedIn = await login(username, password);
      if(loggedIn) {
        addToast('Login succesvol', 'U bent succesvol ingelogd', 'success');
        navigate({
          pathname: redirect,
          replace: true,
        });
      }
      else {
        addToast('Login mislukt', 'Uw gebruikersnaam of wachtwoord is fout', 'error');
      }
    }, [login, navigate, addToast, redirect]
  );

  const validationRules = {
    username: {
      required: 'This is required',
      minLength: { value: 4, message: 'Minimum length should be 4' },
    },
    password: {
      required: 'This is required',
      minLength: { value: 4, message: 'Minimum length should be 4' },
    }
  }

  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }} backgroundColor={"gray.900"} color={'white'}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Image
          src={
            logo_wit
          }></Image>
          <Heading fontSize={'2xl'}>Sign in to your account</Heading>
          <FormProvider {...methods}>
            <form onSubmit={ handleSubmit(handleLogin)}>
            <LabelInput 
              label="Email"
              name="username"
              type="text"
              placeholder="your@email.com"
              validationRules={validationRules.username}
              data-cy='login_username'
            />
            <LabelInput
              label="Password"
              name="password"
              type="password"
              placeholder="password"
              validationRules={validationRules.password}
              data-cy='login_password'
            />
            <Stack alignItems={'flex-end'}>
              <Text color={'blue.500'}>Forgot password?</Text>
              <Button colorScheme={'blue'} variant={'solid'} isLoading={loading} disabled={loading} type='submit' w={'full'} data-cy='login_button'>
                Sign in
              </Button>
            </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={
            bg
          }
        />
      </Flex>
    </Stack>
  )
}
