'use client'

import {
  Heading,
  Avatar,
  Box,
  Center,
  Stack,
  Button,
  useColorModeValue,
  Input,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  ButtonGroup,
  FormControl,
  FormLabel,
  AvatarBadge,
  InputGroup,
  InputRightElement,
  RadioGroup,
  Radio,
  HStack
} from '@chakra-ui/react'

import { memo, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { savePictoire } from '../../backend/api';
import useSWRMutation from 'swr/mutation';
import { useDisclosure, AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogOverlay, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react';
import React from 'react';
import useSWR, { mutate } from 'swr';
import { getAll, updateUser } from '../../backend/api';
import { EditIcon } from '@chakra-ui/icons';
import Toast from '../toast';
import AdminFunction from '../AdminFunction';

const Form = ({ name, btnRef, isOpen, onCancel, id, onDelete, pictoire, authed_user_level, isActief }) => {
  const { isOpen: isDeleteAlert, onOpen: onOpenDeleteAlert, onClose: onCloseDeleteAlert } = useDisclosure()
  const cancelRef = React.useRef()

  const { addToast } = Toast();

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  const { 
    trigger: updateUsers,
    error: saveError,
  } = useSWRMutation(`users/update`, updateUser);

  const onSubmit = useCallback(async (data) => {
    const { name, email, password, level=authed_user_level, is_active=isActief } = data;
    await updateUsers({
      id, 
      email, 
      password, 
      level, 
      name, 
      is_active});
    if(!saveError)
      addToast('Gebruiker aangepast', 'De gebruiker is succesvol aangepast', 'success');
    else
      addToast('Gebruiker aanpassen mislukt', 'Er is iets misgelopen bij het aanpassen van de gebruiker', 'error');
    mutate('users');
  }, [updateUsers]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue
  } = useForm()

  const {
    trigger: saveFile,
    error: saveFileError,
  } = useSWRMutation(`users/update/profilepic/${id}`, savePictoire);

  const handleFile = useCallback(async (file) => {
    await saveFile({
      file: file
    });
  }, [saveFile])

  const hiddenFileInput = useRef(null);
  
  const handleProfileClick = event => {
    hiddenFileInput.current.click();
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
    mutate(`static/profilepics/${id}`);
    if(!saveFileError)
      addToast('Profielfoto aangepast', 'Uw profielfoto is succesvol aangepast', 'success');
    else
      addToast('Profielfoto aanpassen mislukt', 'Er is iets misgelopen bij het aanpassen van uw profielfoto', 'error');
  };

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete])

  function handleIsActiveChange(value) {
    setValue('is_active', value);
  }

  return (
    <Drawer
    isOpen={isOpen}
    placement='right'
    onClose={onCancel}
    finalFocusRef={btnRef}
    size={'md'}
    >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Edit user {name}</DrawerHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
      <DrawerBody>
      <Stack spacing={4}>
      <FormControl display={"flex"} justifyContent={"center "}>
      
      <Avatar
          size={'xl'}
          src={
            `data:image/png;base64,${pictoire}`
          }
          pos={'relative'}
          onClick={handleProfileClick}
          _hover={{ cursor: 'pointer' }}
      >
        <AvatarBadge border={'none'}>
          <EditIcon boxSize={6}/>
        </AvatarBadge>
      </Avatar>
      <input 
        type="file" 
        ref={hiddenFileInput}
        style={{display:'none'}}
        onChange={handleChange} 
      />
      </FormControl>
          <FormControl>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              placeholder={"name"}
              {...register('name')}></Input>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              placeholder="email"
              {...register('email')}></Input>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="wachtwoord">Wachtwoord</FormLabel>
            <InputGroup size='md'>
              <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='wachtwoord'
                {...register('password')}
              />
              <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                  {show ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <AdminFunction>
            <div>
              <FormControl>
                <Stack spacing={4} direction='row' justifyContent={"space-between"}>
                  <FormLabel htmlFor="is_active">Actief </FormLabel>
                  <RadioGroup defaultValue='1' onChange={handleIsActiveChange}>
                    <HStack spacing={4}>
                      <Radio value='1' onChange={handleIsActiveChange} {...register('is_active')}>Ja</Radio>
                      <Radio value='0' onChange={handleIsActiveChange} {...register('is_active')}>Nee</Radio>
                    </HStack>
                  </RadioGroup>
                </Stack>
              </FormControl>
              <FormControl>
                <Stack spacing={4} direction='row' justifyContent={"space-between"}>
                  <FormLabel htmlFor="level">Admin </FormLabel>
                  <RadioGroup defaultValue='0' onChange={setValue} {...register('level')}>
                    <Stack spacing={4} direction='row'>
                      <Radio value='1' onChange={setValue} {...register('level')}>Ja</Radio>
                      <Radio value='0' onChange={setValue} {...register('level')}>Nee</Radio>
                    </Stack>
                  </RadioGroup>
                </Stack>
              </FormControl>
            </div>
          </AdminFunction>
        </Stack>
      </DrawerBody>

      <DrawerFooter justifyContent={'space-between'}>
        <AdminFunction>
          <Button colorScheme={"red"} mr={3} onClick={onOpenDeleteAlert}>
            Delete
          </Button>
        </AdminFunction>
        <AlertDialog
        isOpen={isDeleteAlert}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDeleteAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Delete User
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseDeleteAlert}>
                  Cancel
                </Button>
                <Button colorScheme='red' onClick={handleDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        <ButtonGroup display='flex' justifyContent='flex-end'>
          <Button variant='outline' onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme='teal' type="submit" onClick={onCancel}>
            Save
          </Button>
        </ButtonGroup>
      </DrawerFooter>
      </form>
    </DrawerContent>
  </Drawer>
  )
}

export default memo(function User ({name, email, wachtwoord, is_active, level, id, onDelete, authed_user}) {

  const {
    data: pictoire,
  } = useSWR(`static/profilepics/${id}`, getAll)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <Center py={6}>
      <Box
        maxW={'320px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}>
        <Avatar
          size={'xl'}
          src={
            `data:image/png;base64,${pictoire}`
          }
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: is_active ? 'green.300' : 'red.300',
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'} paddingBottom={2}>
          {name}
        </Heading>
              <Input
                  w='auto'
                  display='inline-flex'
                  isDisabled={true}
                  value={email}
                />
              <Text fontWeight={600} color={'gray.500'} mb={1}>
                Wachtwoord
              </Text>
              <Input
                  w='auto'
                  display='inline-flex'
                  isDisabled={true}
                  type='password'
                  defaultValue={'*******************'}
                />
          
        <Stack mt={8} direction={'row'} spacing={4}>
          <Button
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            colorScheme='orange'
            variant='outline'
            color={'orange'}
            ref={btnRef}
            onClick={onOpen}
            >
            Edit
          </Button>
          <Form btnRef={btnRef} id={id} isOpen={isOpen} onCancel={onClose} onDelete={onDelete} isActief={is_active} name={name} pictoire={pictoire} level={level} is_active={is_active} authed_user_level={authed_user.level}></Form>
        </Stack>
      </Box>
    </Center>
  )
});