'use client'

import {
  Box,
  Center,
  Text,
  Stack,
  useColorModeValue,
  HStack,
  VStack,
} from '@chakra-ui/react'

import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { updatePayment, deleteById } from '../../backend/api'
import React from 'react';
import AdminFunction from '../AdminFunction'
import { SubmitButtonAsForm, DeleteButtonWithAlert } from '../Buttons'

export default function Payment({payments = [], user_id, user_name}) {

  return (
    <Center mt={'3'} minW={'100%'}>
      <Box
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        minW={'100%'}>
        <Stack>
          <Text
            color={'purple.500'}
            textTransform={'uppercase'}
            fontWeight={800}
            fontSize={'sm'}
            letterSpacing={1.1}>
            Payments van {user_name}
          </Text>
          <VStack alignItems={''}>
            <Text
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color="red.200"
              fontSize={'md'}
              fontFamily={'body'}>
              Nog te betalen:
            </Text>
            {payments.filter((payment) => payment.user_id === user_id).filter((payment) => payment.is_paid === false).map(p => (
              <HStack key={p.id} justifyContent={'space-between'}>
                <HStack>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}>
                    {p.name}</Text>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}
                    p={'0'}>
                    {p.amount} EUR
                  </Text>
                </HStack>
                <HStack>
                  <SubmitButtonAsForm
                    apiUrl={`payments/update`}
                    fetcher={updatePayment}
                    fields={{id: p.id, name: p.name, amount: p.amount, user_id: p.user_id, is_paid: true}}
                    tekstIsGeluktTitel='Payment updated'
                    tekstIsGefaaldTitel='Payment update failed'
                    tekstIsGeluktDesc='Payment has been updated'
                    tekstIsGefaaldDesc='Something went wrong while updating payment'
                    background={''}
                    mutateFields={`payments`}
                  >
                    <CheckIcon color={'green.500'} />
                  </SubmitButtonAsForm>
                </HStack>
              </HStack>
            ))}
          </VStack>
          <VStack alignItems={''}>
            <Text
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color="green.200"
              fontSize={'md'}
              fontFamily={'body'}>
              Al Betaald:
            </Text>
            {payments.filter((payment) => payment.user_id === user_id).filter((payment) => payment.is_paid === true).map(p => (
              <HStack key={p.id} justifyContent={'space-between'}>
                <HStack
                textDecoration={"line-through"}
                opacity={'40%'}>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}>
                    {p.name}</Text>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}
                    p={'0'}
                    >
                    {p.amount} EUR
                  </Text>
                </HStack>
                <HStack>
                  <SubmitButtonAsForm
                    apiUrl={`payments/update`}
                    fetcher={updatePayment}
                    fields={{id: p.id, name: p.name, amount: p.amount, user_id: p.user_id, is_paid: false}}
                    tekstIsGeluktTitel='Payment updated'
                    tekstIsGefaaldTitel='Payment update failed'
                    tekstIsGeluktDesc='Payment has been updated'
                    tekstIsGefaaldDesc='Something went wrong while updating payment'
                    background={''}
                    mutateFields={`payments`}
                  >
                    <CloseIcon color={'orange.500'} />
                  </SubmitButtonAsForm>
                  <AdminFunction>
                    <DeleteButtonWithAlert
                      apiUrl={`payments/delete`}
                      fetcher={deleteById}
                      fields={p.id}
                      mutateFields={`payments`}
                      tekstIsGeluktTitel='Payment deleted'
                      tekstIsGefaaldTitel='Payment delete failed'
                      tekstIsGeluktDesc='Payment has been deleted'
                      tekstIsGefaaldDesc='Something went wrong while deleting payment'
                      customDelete={true}
                      colorScheme='red'
                      ml={3}
                    >
                      
                    </DeleteButtonWithAlert>
                  </AdminFunction>
                </HStack>
              </HStack>
            ))}
          </VStack>
        </Stack>
      </Box>
    </Center>
  )
}
