'use client'

import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  AvatarGroup,
  HStack,
  Select,
  Icon,
  VStack,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import useSWR from 'swr'
import { getAll, deleteById, save } from '../../backend/api'
import AsyncData from '../AsyncData'
import React from 'react';
import { CheckIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { MdCloudOff, MdCloudQueue, MdEuro, MdOutlineSend } from 'react-icons/md'
import AdminFunction from '../AdminFunction'
import { Link } from 'react-router-dom';
import { DeleteButtonWithAlert, SubmitButtonAsForm } from '../Buttons'

const EventListItem = ({event_id, titel, desc, start, einde, authed_user, isPastEvent, public_agenda, public_event_id, unconnectedContracts = []}) => {
  const {
    data: acc = [],
    isLoading: isLoadingEventUsers,
    error,
  } = useSWR(`eventusers/event/${event_id}`, getAll)
  const shouldFetch = public_agenda === 'verhuur';
  const { data: eventContract } = useSWR(
    shouldFetch ? `eventcontracts/event/${event_id}` : null,
    getAll
  );

  const [value, setValue] = React.useState('')
  const handleChange = (event) => setValue(event.target.value)

  const isAccepted = acc.some(acceptedEvent => acceptedEvent.user_id === authed_user.authed_user.id && acceptedEvent.event_id === event_id)
  const formattedStart = new Date(start).toLocaleString('nl-BE', { dateStyle: 'short', timeStyle: 'short' });
  const formattedEinde = new Date(einde).toLocaleString('nl-BE', { dateStyle: 'short', timeStyle: 'short' });

  return (
    <Center py={6}>
      <Box
        minW={'240px'}
        maxW={'445px'}
        w={'full'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        opacity={!isPastEvent ? 1 : 0.25}
        >
        <Stack overflow={'hidden'}>
          <HStack justifyContent={'space-between'}>
            <Text
              color={public_agenda === "verhuur" ? 'green.500' : public_agenda === "werking" ? 'blue.500' : 'purple.500'}
              textTransform={'uppercase'}
              fontWeight={800}
              fontSize={'sm'}
              letterSpacing={1.1}>
              Event - {public_agenda ? public_agenda : "Privé"}
            </Text>
              <HStack>
                { public_event_id ?
                  <Icon as={MdCloudQueue} boxSize={4} color='blue.500' />
                  :
                  <Icon as={MdCloudOff} boxSize={4} color='red.200' />
                }
                <AdminFunction>
                  <DeleteButtonWithAlert
                    apiUrl={'events/delete'}
                    fields={event_id}
                    fetcher={deleteById}
                    tekstIsGeluktTitel={'Event deleted'}
                    tekstIsGefaaldTitel={'Failed to delete event'}
                    tekstIsGeluktDesc={'Event has been deleted'}
                    tekstIsGefaaldDesc={'Failed to delete event'}
                    mutateFields={'events'}
                    colorScheme='red'
                    ml={3}
                  />
                </AdminFunction>
              </HStack>
            </HStack>
          <Heading
            // eslint-disable-next-line react-hooks/rules-of-hooks
            color={useColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            fontFamily={'body'}>
            {titel}
          </Heading>
          <Text color={'gray.500'} height={'40px'} maxW={'221px'} overflow={'hidden'}>
            {desc}
          </Text>
        </Stack>
        <Stack direction={'column'} spacing={0} fontSize={'sm'} paddingTop={"2"}>
          <Text fontWeight={600}>Uur</Text>
          <Text color={'gray.500'}>{formattedStart} · {formattedEinde}</Text>
        </Stack>
          {public_agenda === 'verhuur' && (
          <VStack mt={'1em'} alignItems={'flex-start'}>
            {(eventContract !== undefined && eventContract[0]) ? (
              <>
                <Text>Contract: {eventContract[0].contract_id}</Text>
                <HStack>
                  <SubmitButtonAsForm
                    colorScheme='teal'
                    apiUrl={`eventcontracts/${eventContract[0].id}`}
                    fields={{
                      id: eventContract[0].id,
                      event_id: eventContract[0].event_id,
                      contract_id: eventContract[0].contract_id,
                      isBetaald: true,
                      isVerstuurd: eventContract[0].isVerstuurd
                    }}
                    mutateFields={`eventcontracts/event/${event_id}`}
                    tekstIsGeluktTitel='Betaling gelukt'
                    tekstIsGefaaldTitel='Betaling mislukt'
                    tekstIsGeluktDesc='U heeft de betaling succesvol uitgevoerd'
                    tekstIsGefaaldDesc='Er is iets misgelopen bij de betaling'
                    isDisabled={eventContract[0].isBetaald}
                    mr={'1em'}
                  >
                    <MdEuro size={'32'}/>
                  </SubmitButtonAsForm>
                  <Icon mr={'1em'} as={MdOutlineSend} boxSize={8} color={eventContract[0].isVerstuurd ? 'green.500': 'red.500'} />
                  <Link to={`/contracts?contract=${eventContract[0].contract_id}`}>
                    <ExternalLinkIcon boxSize={8} color={'purple.500'}/>
                  </Link>
                </HStack>
              </>
            ) : (
              <>
                <Text>Contract: {value}</Text>
                <AdminFunction>
                  <InputGroup>
                    <Select placeholder='Select contract' padding={'0'} mb={'2'} background={"gray.700"} onChange={handleChange}>
                      {unconnectedContracts.map(contract => (
                        <option key={contract.id} value={contract.id}>{contract.id} - {contract.name}</option>
                      ))}
                    </Select>
                    <InputRightElement>
                      <SubmitButtonAsForm 
                        colorScheme='green'
                        apiUrl={'eventcontracts'}
                        fetcher={save}
                        fields={{event_id: event_id, contract_id: value, isBetaald: false, isVerstuurd: false}}
                        tekstIsGeluktTitel='Contract opslaan gelukt'
                        tekstIsGefaaldTitel='Contract opslaan mislukt'
                        tekstIsGeluktDesc='U heeft het contract succesvol opgeslagen'
                        tekstIsGefaaldDesc='Er is iets misgelopen bij het opslaan van het contract'
                        h={'32px'}
                        w={'32px'}
                        size='sm'
                        mutateFields={`eventcontracts/event/${event_id}`}
                      >
                        <CheckIcon/>
                      </SubmitButtonAsForm>
                    </InputRightElement>
                  </InputGroup>
                </AdminFunction>
              </>
            )}
          </VStack>
          )}
          <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
          <Popover>
              <PopoverTrigger>
              <Box ml={'0'}>
                <AsyncData key={event_id} loading={isLoadingEventUsers} error={error}>
                  <AvatarGroup size='md' max={4}>
                    {acc.length > 0 ? (
                      acc.filter((acceptedEvent) => acceptedEvent.event_id === event_id).map(acceptedEvent => (
                        <Avatar src={`data:image/png;base64,${acceptedEvent.profilepic}`}></Avatar>
                      ))
                    ) : (
                      <Avatar opacity={'0'} src={``}></Avatar>
                    )}
                  </AvatarGroup>
                </AsyncData>
              </Box>
              </PopoverTrigger>
              <PopoverContent zIndex={"9999"}>
              <PopoverHeader fontWeight='semibold'>Deze Mensen komen:</PopoverHeader>
              <PopoverArrow bg={'pink.500'} />
              <PopoverCloseButton bg='purple.500' />
              <PopoverBody>
                <AsyncData loading={isLoadingEventUsers} error={error}>
                    <div>
                    {acc.filter((acceptedEvent) => acceptedEvent.event_id === event_id).map(acceptedEvent => (
                      <AsyncData loading={isLoadingEventUsers}>
                        {" · " + acceptedEvent.name}
                      </AsyncData>
                    ))}
                    </div>
                </AsyncData>

              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Stack>
        {/* {public_agenda !== "verhuur" && ( */}
          <HStack mt={'2'} >
            <SubmitButtonAsForm
              colorScheme='green'
              apiUrl={`eventusers/${event_id}`}
              fetcher={save}
              fields={{event_id: event_id, user_id: authed_user.authed_user.id}}
              mutateFields={`eventusers/event/${event_id}`}
              tekstIsGeluktTitel='Accepteren gelukt'
              tekstIsGefaaldTitel='Accepteren mislukt'
              tekstIsGeluktDesc='U heeft het event succesvol geaccepteerd'
              tekstIsGefaaldDesc='Er is iets misgelopen bij het accepteren van het event'
              formsize={'full'}
              isDisabled={isAccepted || isPastEvent}
            >
            Accept
            </SubmitButtonAsForm>
            <SubmitButtonAsForm
              colorScheme='red'
              apiUrl={`eventusers/delete`}
              fetcher={deleteById}
              fields={event_id}
              mutateFields={`eventusers/event/${event_id}`}
              tekstIsGeluktTitel='Annuleren gelukt'
              tekstIsGefaaldTitel='Annuleren mislukt'
              tekstIsGeluktDesc='U heeft het event succesvol geannuleerd'
              tekstIsGefaaldDesc='Er is iets misgelopen bij het annuleren van het event'
              formsize={'full'}
              isDisabled={!isAccepted || isPastEvent}
            >
              <CloseIcon/>
            </SubmitButtonAsForm>
        </HStack>
        {/* )} */}
      </Box>
    </Center>
  )
}
export default EventListItem