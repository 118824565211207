'use client'

import {
    HStack,
    VStack,
    Heading,
    Flex,
    Container,
    SimpleGrid,
    Stack,
    Button,
    Input,
    FormErrorMessage,
    ButtonGroup,
    Popover, 
    PopoverTrigger, 
    PopoverContent, 
    useDisclosure,
    FocusLock,
    PopoverArrow,
    PopoverCloseButton
  } from '@chakra-ui/react'

import { useForm } from "react-hook-form"
import { FormControl, FormLabel } from "@chakra-ui/react"
import { PlusSquareIcon } from "@chakra-ui/icons"
import User from '../components/users/User.js'
import React, { useCallback } from "react";
import { deleteById, getAll, save } from '../backend/api/index.js';
import useSWR from 'swr';
import AsyncData from '../components/AsyncData.jsx';
import useSWRMutation from 'swr/mutation'
import { useSWRConfig } from 'swr'
import AdminFunction from '../components/AdminFunction.jsx'

const Form = ({ onCancel }) => {
    const {
        trigger: saveUser,
        error: saveError,
    } = useSWRMutation('users', save);

    const onSubmit = useCallback(async (data) => {
        const { name, email, wachtwoord, level } = data;
        await saveUser({ 
            name: name, 
            email: email, 
            password: wachtwoord, 
            level: parseInt(level) });
        // reset();
    }, [ saveUser])
  
    const {
      handleSubmit,
      register,
      formState: {errors, isSubmitting},
    } = useForm()
  
    return (
      <Stack spacing={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <FormLabel htmlFor={'naam'}>Naam</FormLabel>
            <Input 
              id='name'
              placeholder="naam"
              {...register('name', {
                required: 'This is required'
              })}></Input>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={'email'}>Email</FormLabel>
            <Input 
              id='email'
              placeholder="email"
              {...register('email', {
                required: 'This is required'
              })}></Input>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={'level'}>Level, 0: user, 1: admin</FormLabel>
            <Input 
              id='level'
              placeholder="level"
              {...register('level', {
                required: 'This is required'
              })}></Input>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={'wachtwoord'}>Wachtwoord</FormLabel>
            <Input 
              id='wachtwoord'
              placeholder="wachtwoord"
              {...register('wachtwoord', {
                required: 'This is required'
              })}
              type='password'></Input>
          </FormControl>
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
          <ButtonGroup display='flex' justifyContent='flex-end' mt={"2"}>
            <Button variant='outline' onClick={onCancel}>
              Cancel
            </Button>
            <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
              Save
            </Button>
          </ButtonGroup>
        </form>
      </Stack>
    )
  }

const Users = (authed_user) => {
    const {
        data: users = [],
        isLoading,
        error,
    } = useSWR('users', getAll)
    const { mutate } = useSWRConfig()
    const { trigger: deleteUser, error: deleteError } = useSWRMutation('users/delete', deleteById)
    const onSubmit = useCallback(async (data) => {
      await deleteUser(data);
      mutate('users');
    }, [deleteUser, mutate])

    const { onOpen, onClose, isOpen } = useDisclosure()
    return (
        <Flex
        w={'99%'}
        ml={{base: 0, md: 0}}>
            <Container maxW={'8xl'}>
                <HStack>
                  <Heading paddingRight={'8'} paddingBottom={"4"} paddingTop={'4'}>Users</Heading>
                  <AdminFunction>
                    <Flex justifyContent="center">
                      <Popover
                          isOpen={isOpen}
                          onOpen={onOpen}
                          onClose={onClose}
                          closeOnBlur={false}
                          placement="bottom" 
                          isLazy
                      >
                          <PopoverTrigger>
                              <Button rightIcon={<PlusSquareIcon />} colorScheme="green" w="fit-content">
                              Create
                              </Button>
                          </PopoverTrigger>
                          <PopoverContent p={5}>
                          <FocusLock returnFocus persistentFocus={false}>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <Form onCancel={onClose} />
                          </FocusLock>
                          </PopoverContent>
                      </Popover>
                    </Flex>
                  </AdminFunction>
                </HStack>
                <AsyncData loading={isLoading} error={error || deleteError}>
                    <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 3 }} spacing={0} justifyItems={'center'}>
                    {users.map((user) => (
                        <HStack key={user.id} align={'top'} zIndex={'base'}>
                            <VStack align={'start'}>
                                <User key={user.id} authed_user={authed_user.authed_user} onDelete={onSubmit} {...user}></User>
                            </VStack>
                        </HStack>
                    ))}
                    </SimpleGrid>
                </AsyncData>
            </Container>
        </Flex>
    )
}

export default Users