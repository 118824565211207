import {
    createContext,
    useState,
    useCallback,
    useMemo,
    useContext,
    useEffect,
} from 'react';
import useSWRMutation from 'swr/mutation';
import * as api from '../backend/api/index.js';
import Cookies from 'js-cookie';

const JWT_TOKEN_KEY = 'jwtToken';
const USER_ID_KEY = 'userId';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [ready, setReady] = useState(false);
    const [isAuthed, setIsAuthed] = useState(false);
    const [token, setToken] = useState(Cookies.get(JWT_TOKEN_KEY));
    const [user, setUser] = useState(null);

    const {
        isMutating: loading,
        error,
        trigger: doLogin,
    } = useSWRMutation('/token', api.post);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (token && storedUser) {
            api.setAuthToken(token);
            setUser(JSON.parse(storedUser));
            setIsAuthed(true);
        }
        setReady(true);
    }, [token]);

    const login = useCallback(async (username, password) => {
        try {
            const { access_token: token, user } = await doLogin({ username, password });

            setToken(token);
            setUser(user);

            Cookies.set(JWT_TOKEN_KEY, token, { expires: 121, sameSite: 'strict', secure: true });
            Cookies.set(USER_ID_KEY, user.id, { expires: 121, sameSite: 'strict', secure: true });

            localStorage.setItem('user', JSON.stringify({
                id: user.id,
                name: user.name,
                email: user.email,
                level: user.level,
            }));

            setIsAuthed(true);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }, [doLogin]);

    const logout = useCallback(() => {
        setToken(null);
        setUser(null);
        setIsAuthed(false);

        Cookies.remove(JWT_TOKEN_KEY);
        Cookies.remove(USER_ID_KEY);

        localStorage.removeItem('user');
    }, []);

    const value = useMemo(
        () => ({
            token,
            user,
            error,
            ready,
            loading,
            isAuthed,
            login,
            logout,
        }),
        [token, user, error, ready, loading, isAuthed, login, logout]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};