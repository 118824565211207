import axiosAPI from "./axios";
import axiosRoot from 'axios';

const baseUrl = process.env.REACT_APP_API_URL || 'https://api.degaljaar.be';

export const axios = axiosRoot.create({
    baseURL: baseUrl,
});

export const setAuthToken = (token) => {
    if(token) {
        axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers['Authorization'];
    }
};

export const post = async (url, { arg }) => {
    const {
      data,
    } = await (axiosAPI.post(`${baseUrl}/token`, {
        username: arg['username'],
        password: arg['password'],
    }, {headers: {'content-type': 'application/x-www-form-urlencoded'}}));
    return data;
};

export async function getAll(url) {
    const {
      data,
    } = await axios.get(`${baseUrl}/${url}`);
    return data;
};

export const save = async (url, { arg: body}) => {
    await axios.post(`${baseUrl}/${url}`, body)
};

export const savePictoire = async (url, { arg: body}) => {
    await axios.post(`${baseUrl}/${url}`, body, {headers: {'content-type': 'multipart/form-data'}})
};

export const getById = async (url, { arg: id }) => {
    const {
      data,
    } = await axios.get(`${baseUrl}/${url}`);
    return data;
}

export const updateById = async (url, { arg: body }) => {
    const { id, ...values} = body;
    await axios.put(`${baseUrl}/${url}`, values);
};

export const deleteById = async (url, { arg: id }) => {
    await axios.delete(`${baseUrl}/${url}/${id}`);
};

export const deletePublicEvent = async (url, { arg: {public_event_id, public_agenda} }) => {
    const params = {public_event_id: public_event_id, public_agenda: public_agenda}
    await axios.delete(`${baseUrl}/${url}`, { params });
}

export const updatePayment = async (url, { arg: {id, name, amount, user_id, is_paid} }) => {
    await axios.put(`${baseUrl}/${url}`, {id: id, name: name, amount: amount, user_id: user_id, is_paid: is_paid});
}

export const updateUser = async (url, { arg: {id, name, email, password, level, is_active} }) => {
    await axios.put(`${baseUrl}/${url}`, {id: id, name: name, email: email, password: password, level: level, is_active: is_active});
}

export const updateCoin = async (url, { arg: {user_id, amount} }) => {
    await axios.put(`${baseUrl}/${url}`, {user_id: user_id, amount: amount});
}

export const create = async (url, { arg }) => {
    const token = localStorage.getItem('jwtToken')
    await axios.post('/events/', {title: "hallo", desc: "hallo van react", start: "2019-12-03 07:30", end: "2019-12-03 07:30", public: 1}, {headers: { Authorization: `Bearer ${token}` }});
}

export const updateCheckout = async (url, { arg: body }) => {
    await axios.put(`${baseUrl}/${url}`, body);
};