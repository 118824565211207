import { useToast } from "@chakra-ui/react";
// types: "success", "info", "warning", "error"
const Toast = () => {
  const toast = useToast();
  const addToast = (titel, description, status) => {
    toast({
        title: titel,
        description: description,
        status: status,
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        containerStyle: {
          marginTop: '2em',
          marginRight: '2em',
        },
      }
    )
  }
  return { addToast };
}
export default Toast;