'use client'

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  useColorMode,
  Image
} from '@chakra-ui/react'
import {
  FiHome,
  FiMenu,
  FiChevronDown,
  FiPaperclip
} from 'react-icons/fi'

import {
  MdPayment,
  MdPeople,
  MdOutlineEventNote,
  MdOutlineCurrencyBitcoin,
  MdShoppingCart,
  MdLightbulbOutline 
} from 'react-icons/md'

import { BiSolidDrink } from 'react-icons/bi'

import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import logo_wit from '../../assests/logo_wit.png'
import logo_zwart from '../../assests/logo_web_512_black-1.png'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import React from "react";
import { useAuth } from '../../contexts/Auth.context.jsx'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr';
import { getAll } from '../../backend/api/index.js'
import AsyncData from '../AsyncData.jsx'
import Toast from '../toast.jsx'

const LinkItems = [
  { name: 'Home', icon: FiHome, linkje: '/' },
  { name: 'Events', icon: MdOutlineEventNote, linkje: '/events' },
  { name: 'Bar', icon: BiSolidDrink, linkje: '/bar' },
  { name: 'Payments', icon: MdPayment, linkje: '/payments' },
  { name: 'Users', icon: MdPeople, linkje: '/users' },
  { name: 'Coins', icon: MdOutlineCurrencyBitcoin, linkje: '/coins'},
  { name: 'Contracten', icon: FiPaperclip , linkje: '/contracts' },
  { name: 'Checkouts', icon: MdShoppingCart  , linkje: '/checkouts' },
  { name: 'Lights', icon: MdLightbulbOutline  , linkje: '/lights' }
]

const SidebarContent = ({ onClose, authed_user, ...rest }) => {

  return (
    <Box
      zIndex="10"
      // transition="1s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between" mt={{base: 6, lg: 0}}>
        {/* hier nog een logo in het zwart toevoegen */}
        <Image src={useColorModeValue(logo_zwart, logo_wit)}/>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} linkje={link.linkje} onClick={onClose}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

const NavItem = ({ icon, children, linkje, authed_user, ...rest }) => {
  return (
    <ChakraLink as={ReactRouterLink} to={linkje}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="20"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </ChakraLink>
  )
}

const MobileNav = ({ onOpen, authed_user, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const { logout } = useAuth();
  const navigate = useNavigate();

  function OnSubmit() {
    logout()
    return navigate({
      pathname: '/login',
      })
  }

  const {
    data: pictoire,
  } = useSWR(`static/profilepics/${authed_user.id}`, getAll)

  const { addToast } = Toast();

  return (
    <Flex
      zIndex={'9'}
      w="full"
      mr={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Button onClick={toggleColorMode} marginRight="5" marginLeft="5" data-cy='theme_button'>
        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
      </Button>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <AsyncData>
                  <Avatar
                    size={'sm'}
                    src={
                      `data:image/png;base64,${pictoire}`
                    }
                  />
                </AsyncData>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{authed_user.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {authed_user.level === 1 ? "Admin" : "User"}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList>
              {/* <MenuDivider /> */}
              <MenuItem color="red.500" onClick={() => {
                OnSubmit()
                addToast("Succesfully logged out", "", "success")
                }}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const SidebarWithHeader = ({ SpaceVuller }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} >
        <SidebarContent authed_user={user} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} authed_user={user} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} authed_user={user} />
        <Box mr={{ base: 0, md: 6 }} ml={{ base: 0, md: 60}} p="0" >
          <SpaceVuller authed_user={user}></SpaceVuller>
        </Box>
    </Box>
  )
}

export default SidebarWithHeader