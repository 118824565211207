'use client'

import { 
  Avatar,
  Heading,
  Image,
  HStack,
  Tr,
  Td,
} from '@chakra-ui/react'
import coingif from '../../assests/coin.gif';
import useSWR from 'swr';
import { updateCoin } from '../../backend/api';
import { getAll } from '../../backend/api/index.js';
import React from 'react';
import AsyncData from '../AsyncData.jsx';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import AdminFunction from '../AdminFunction.jsx';
import { SubmitButtonAsForm } from '../Buttons.jsx';

export default function Coin({coin}) {

  const {
    data: pictoire,
    isLoadingPictoire,
  } = useSWR(`static/profilepics/${coin.user_id}`, getAll)
  return (
    <AsyncData loading={isLoadingPictoire}>
      <Tr>
        <Td paddingInline={{base:2, md:6}} overflow={'hidden'}>
          <HStack alignItems={'center'}>
              <Avatar size={'sm'} src={`data:image/png;base64,${pictoire}`}/>
              <Heading fontSize={'sm'} align={'center'}>{coin.user_name}</Heading>
          </HStack>
        </Td>
        <Td paddingInline={{base:2, md:6}}>
          <HStack>
            <Heading fontSize={'sm'} align={'center'}>{coin.amount}</Heading>
            <Image src={coingif} alt={'coin'} boxSize={'20px'} />
          </HStack>
        </Td>
        <AdminFunction>
          <Td paddingInline={{base:2, sm:6}}>
            <HStack justifyContent={'end'}>
              <SubmitButtonAsForm
                apiUrl={`coins/update`}
                fetcher={updateCoin}
                fields={{user_id: coin.user_id, amount: coin.amount + 1}}
                mutateFields={`coins`}
                colorScheme={'green'}
                w={'40px'}
                h={'40px'}
              >
                <AddIcon />
              </SubmitButtonAsForm>
              <SubmitButtonAsForm
                apiUrl={`coins/update`}
                fetcher={updateCoin}
                fields={{user_id: coin.user_id, amount: coin.amount - 1}}
                mutateFields={`coins`}
                colorScheme={'red'}
                w={'40px'}
                h={'40px'}
              >
                <MinusIcon />
              </SubmitButtonAsForm>
            </HStack>
          </Td>
        </AdminFunction>
      </Tr>
    </AsyncData>
  )
};