'use client'

import {
    HStack,
    VStack,
    Heading,
    Flex,
    Container,
    SimpleGrid,
    Divider,
    Box,
  } from '@chakra-ui/react'

import EventListItem from '../components/events/Event-comp.js'
import CreateEventButton from '../components/events/CreateEventButton.js'
import React from "react";
import { getAll } from '../backend/api/index.js';
import useSWR from 'swr';
import AsyncData from '../components/AsyncData.jsx';
import AdminFunction from '../components/AdminFunction.jsx';

const EventList = (authed_user) => {
    const {
        data: events = [],
        isLoading,
        error,
    } = useSWR('events', getAll)

    const {
        data: eventsContracts = [],
        isLoading: eventsContractsLoading,
    } = useSWR(`eventcontracts`, getAll);

    const pastEvents = events
        .filter(event => new Date(event.end) < new Date())
        .filter(event => event.public_agenda === 'verhuur' ? eventsContracts.some(contract => contract.event_id === event.id) : true)
        .sort((a, b) => new Date(b.start) - new Date(a.start))

    const upcomingEvents = events
        .filter(event => new Date(event.start) > new Date())
        .sort((a, b) => new Date(a.start) - new Date(b.start))

    const activeEvents = events
        .filter(event => (new Date(event.start) < new Date()) && (new Date() < new Date(event.end)))
        .sort((a, b) => new Date(a.start) - new Date(b.start))

    const eventsWithoutContracts = events
        .filter(event => new Date(event.end) < new Date())
        .filter(event => event.public_agenda  === 'verhuur' ? !eventsContracts.some(contract => contract.event_id === event.id) : false);

    const {
        data: unconnectedSubmissions = [],
        isLoading: unconnectedLoading,
    } = useSWR(`eventcontracts/unconnected`, getAll);
    
    return (
        <Flex
        w={'99%'}
        ml={{base: 0, md: 0}}>
            <Container maxW={'8xl'}>
                <HStack>
                    <Heading paddingRight={'8'} paddingBottom={"4"} paddingTop={'4'}>Komende Events</Heading>
                    <HStack>
                        <AdminFunction>
                            <CreateEventButton></CreateEventButton>
                        </AdminFunction>
                    </HStack>
                </HStack>
                <AsyncData loading={isLoading} error={error}>
                    <Heading as={'h2'} size={'md'}>Active Events</Heading>
                    <Box position='relative' padding='2'>
                        <Divider />
                    </Box>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4}} spacing={10} zIndex='popover' justifyItems={'center'}>
                    {activeEvents.length === 0 ? <h4 className="alert-heading" style={{ margin: '10px' }}>No active events</h4> : activeEvents.map((event) => (
                            <HStack align={'top'} zIndex={'base'}>
                                <VStack align={'start'}>
                                    <EventListItem event_id={event.id} titel={event.title} desc={event.desc} start={event.start} einde={event.end} authed_user={authed_user} isPublic={event.public} public_agenda={event.public_agenda} isPastEvent={false} public_event_id={event.public_event_id} unconnectedContracts={unconnectedSubmissions} unconnectedLoading={unconnectedLoading}/>
                                </VStack>
                            </HStack>
                        ))}
                    </SimpleGrid>
                    <Heading as={'h2'} size={'md'}>Old events without contract</Heading>
                    <Box position='relative' padding='2'>
                        <Divider />
                    </Box>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4}} spacing={10} zIndex='popover' justifyItems={'center'}>
                        {eventsWithoutContracts.length === 0 ? <h4 className="alert-heading" style={{ margin: '10px' }}>No events without contract</h4> : eventsWithoutContracts.map((event) => (
                            <HStack align={'top'} zIndex={'base'}>
                                <VStack align={'start'}>
                                    <EventListItem event_id={event.id} titel={event.title} desc={event.desc} start={event.start} einde={event.end} authed_user={authed_user} isPublic={event.public} public_agenda={event.public_agenda} isPastEvent={false} public_event_id={event.public_event_id} unconnectedContracts={unconnectedSubmissions} unconnectedLoading={unconnectedLoading}/>
                                </VStack>
                            </HStack>
                        ))}
                    </SimpleGrid>
                    <Heading as={'h2'} size={'md'}>Upcoming Events</Heading>
                    <Box position='relative' padding='2'>
                        <Divider />
                    </Box>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4}} spacing={10} zIndex='popover' justifyItems={'center'}>
                    {upcomingEvents.length === 0 ? <h4 className="alert-heading" style={{ margin: '10px' }}>No upcoming events</h4> : upcomingEvents.map((event) => (
                            <HStack align={'top'} zIndex={'base'}>
                                <VStack align={'start'}>
                                    <EventListItem event_id={event.id} titel={event.title} desc={event.desc} start={event.start} einde={event.end} authed_user={authed_user} isPublic={event.public} public_agenda={event.public_agenda} isPastEvent={false} public_event_id={event.public_event_id} unconnectedContracts={unconnectedSubmissions} unconnectedLoading={unconnectedLoading}/>
                                </VStack>
                            </HStack>
                        ))}
                    </SimpleGrid>
                    <Heading as={'h2'} size={'md'}>Past Events</Heading>
                    <Box position='relative' padding='2'>
                        <Divider />
                    </Box>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4}} spacing={10} zIndex='popover' justifyItems={'center'}>
                    {pastEvents.length === 0 ? <h4 className="alert-heading" style={{ margin: '10px' }}>No past events</h4> : pastEvents.map((event) => (
                            <HStack align={'top'} zIndex={'base'}>
                                <VStack align={'start'}>
                                    <EventListItem event_id={event.id} titel={event.title} desc={event.desc} start={event.start} einde={event.end} authed_user={authed_user} isPublic={event.public} isPastEvent={true} public_agenda={event.public_agenda} public_event_id={event.public_event_id} unconnectedContracts={unconnectedSubmissions} unconnectedLoading={unconnectedLoading}/>
                                </VStack>
                            </HStack>
                        ))}
                    </SimpleGrid>
                </AsyncData>
            </Container>
        </Flex>
    )
}

export default EventList