// Stats.js
import { 
  useColorModeValue,
  Text,
  VStack
} from "@chakra-ui/react";
import ResizableBox from "../components/ResizeableBox";
import React from "react";
import { 
  Chart
} from "react-charts";
import useSWR from "swr";
import { getAll } from "../backend/api/index.js";
import AsyncData from "../components/AsyncData";
import Graph from "../components/Graph.jsx";

const Stats = () => {

  const {
    data: checkouts = [],
    isLoading: isLoadingCheckouts,
  } = useSWR('checkouts', getAll)

  const data = [
    {
      label: 'Winst per checkout',
      data: checkouts.map(checkout => ({
        date: new Date(checkout.date),
        winst: checkout.end_amount - checkout.start_amount,
        end_amount: checkout.end_amount,
        start_amount: checkout.start_amount,
      }))
    }
  ]

  const primaryAxisOmzet = React.useMemo(
    () => ({
      getValue: datum => datum.date,
    }),
    []
  )

  const secondaryAxesOmzet = React.useMemo(
    () => [
      {
        getValue: datum => datum.start_amount,
      },
    ],
    []
  )



  return (
    <>
      <AsyncData isLoading={isLoadingCheckouts}>
        <VStack>
          <Text color={'purple.500'} fontWeight={700} fontSize={'large'}>Winst per checkout</Text>
          <ResizableBox
            style={{
              borderRadius: "5px",
            }}
            title="Winst per checkout"
          >
            <div style={{ width: "100%", height: "100%" }}>
              {checkouts.length > 0 ? (
                <Graph data={data} primaryAxis={primaryAxisOmzet} secondaryAxes={secondaryAxesOmzet} ></Graph>
              ) : (
                <h2>No checkouts to show</h2>
              )}
            </div>
          </ResizableBox>
        </VStack>
      </AsyncData>
    </>
  );
};

export default Stats;