'use client'

import {
    HStack,
    VStack,
    Heading,
    Flex,
    Container,
    SimpleGrid,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    useDisclosure,
    FocusLock,
    Stack,
    FormControl,
    FormLabel,
    Input,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    Select,
    FormErrorMessage,
    ButtonGroup,

  } from '@chakra-ui/react'
import AsyncData from '../components/AsyncData.jsx';
import Payment from '../components/payments/payment.js'
import React from "react";
import { getAll, update } from '../backend/api/index.js';
import useSWR from 'swr';
import { PlusSquareIcon } from "@chakra-ui/icons"
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { useForm } from 'react-hook-form'
import { save } from '../backend/api/index.js'
import { useState } from 'react'
import AdminFunction from '../components/AdminFunction.jsx';

const Form = ({ onCancel, users = [] }) => {
  const [amount, setAmount] = useState(1.00);

  const handleChangeAmount = (value) => {
    if (!value || /^-?\d*[.,]?\d*$/.test(value)) {
      setAmount(value);
    }
  };
  const {
    trigger: savePayment,
    error: saveError
  } = useSWRMutation('payments', save);

  const onSubmit = useCallback(async (data) => {
      const { name, amount, user_id } = data;
      await savePayment({
          amount: amount,
          name: name,
          user_id: user_id,
          is_paid: 0
      });
  }, [ savePayment])


  const {
    handleSubmit,
    register,
    formState: {errors, isSubmitting},
    onchange,
  } = useForm()

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormLabel htmlFor="name">Naam</FormLabel>
          <Select placeholder='Select option' padding={'0'} {
              ...register('user_id', {
                  required: 'This is required'
              })
          } onChange={onchange
          }>
          {
              users.sort(function(a, b){
                  if(a.name < b.name) { return -1; }
                  if(a.name > b.name) { return 1; }
                  return 0;
              }).map((user) => (
                  <option key={user.id} value={user.id}>{user.name}</option>
              ))
          }
          </Select>
          </FormControl>
        <FormControl>
          <FormLabel htmlFor="name">Beschrijving</FormLabel>
          <Input
            id="name"
            type="text"
            {...register('name', {required: true})}
            onChange={onchange}/>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor={"prijs"}>Totaal in euro</FormLabel>
          <NumberInput defaultValue={1.00} step={0.01} type="text"
          {...register('amount', {
            required: 'This is required'
          })}
          onChange={(e) => handleChangeAmount(e)}>
            <NumberInputField type="text" 
            inputMode="text" 
            pattern="\d*\.?\d*" 
            onChange={(e) => handleChangeAmount(e.target.value)}/>
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
        <ButtonGroup display='flex' justifyContent='flex-end' mt={'2'}>
          <Button variant='outline' onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
            Save
          </Button>
        </ButtonGroup>
      </form>
    </Stack>
  )
}

const Payments = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const {
      data: users = [],
      isLoading: isLoadingUsers,
  } = useSWR('users', getAll)

  const {
      data: payments = [],
      isLoading: isLoadingPayments,
  } = useSWR('payments', getAll)

  return (
    <Flex
    w={'99%'}
    ml={{base: 0, md: 0}}>
        <Container maxW={'8xl'}>
            <HStack>
                <Heading paddingRight={'8'} paddingBottom={"4"} paddingTop={'4'}>Payments</Heading>
                <AdminFunction>
                  <Popover
                      isOpen={isOpen}
                      onOpen={onOpen}
                      onClose={onClose}
                      closeOnBlur={false}
                      placement="bottom" 
                      isLazy
                  >
                      <PopoverTrigger>
                      <PopoverTrigger>
                          <Button rightIcon={<PlusSquareIcon />} colorScheme="green" w="fit-content">
                          Create
                          </Button>
                      </PopoverTrigger>
                      </PopoverTrigger>
                      <PopoverContent p={5}>
                      <FocusLock returnFocus persistentFocus={false}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <Form onCancel={onClose} users={users}/>
                      </FocusLock>
                      </PopoverContent>
                  </Popover>
                </AdminFunction>
            </HStack>
            
            <SimpleGrid columns={{ base: 1 }} spacing={0} justifyItems={'center'}>
              <AsyncData loading={isLoadingPayments && isLoadingUsers}>
                  {users.map((user) => (
                    <VStack align={'start'} width={'100%'}>
                        <Payment payments={payments} user_id={user.id} user_name={user.name}></Payment>
                    </VStack>
                  ))}
              </AsyncData>
            </SimpleGrid>
        </Container>
    </Flex>
  )
}

export default Payments