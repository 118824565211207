import { Flex, Button, Icon } from "@chakra-ui/react"
import { GrPowerReset } from "react-icons/gr"

const CreateResetButton = () => {
    function resetTotaal() {
      document.getElementById('totaal').innerHTML = 0
      var products = document.getElementsByClassName('product');
      [].slice.call( products ).forEach(function ( div ) {
        div.innerHTML = 0;
    });
    }
    return (
        <Flex justifyContent="center">
          <Button rightIcon={<Icon as={GrPowerReset} />} colorScheme="red" w="fit-content" onClick={() => resetTotaal()}>
            Reset
          </Button>
        </Flex>
    )
}

export default CreateResetButton