import React, {
    useState
} from "react";
import mqtt from "mqtt";
import { HexColorPicker } from "react-colorful";
import { Container } from "@chakra-ui/react";

export const Websocket = () => {
  const client = mqtt.connect(process.env.REACT_APP_MQTT_BROKER_URL, {
    username: process.env.REACT_APP_MQTT_USERNAME,
    password: process.env.REACT_APP_MQTT_PASSWORD,
  });
  
  const [mesg, setMesg] = useState('Waiting on message...')
  useState(() => {
    client.on("connect", () => {
      client.subscribe("wled/logo/v");
    });
  }, []);

  const rgbToHex = (r, g, b) => {
    const colorHex = "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
    return colorHex;
  };

  client.on("message", (topic, message) => {
    const messageStr = message.toString();
    
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(messageStr, "text/xml");
    
    // Assuming 'col' values are in <cl> elements
    const clElements = xmlDoc.querySelectorAll("cl");
    const colValues = Array.from(clElements).map(elem => elem.textContent);
    
    setMesg(rgbToHex(colValues[0], colValues[1], colValues[2]));
    setColor(rgbToHex(colValues[0], colValues[1], colValues[2]));
  });
  // "/g voor status 0 = uit"
  const sendMessage = (value) => {
    client.publish("wled/logo/col", value);
  }

  const [color, setColor] = useState("#aabbcc");

  const handleChange = (value) => {
    sendMessage(value);
    setColor(value);
  };

  

  return (
    <>
    <Container ml={'1rem'} mt={'1rem'}>
      <h1>{mesg}</h1>
      <HexColorPicker color={color} onChange={handleChange} />
    </Container>
    </>
  )
};
