import ResizableBox from "./ResizeableBox";
import React from "react";
import { 
  Chart
} from "react-charts";
import { useColorModeValue } from "@chakra-ui/react";

const Graph = ({ data, primaryAxis, secondaryAxes }) => {

  const dark = useColorModeValue(false, true)

  return (
    <ResizableBox>
      <Chart
        options={{
          data,
          primaryAxis,
          secondaryAxes,
          dark: dark,
        }}
      />
    </ResizableBox>
  )
}

export default Graph;