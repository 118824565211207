'use client'

import { 
  Heading,
  HStack,
  Tr,
  Td,
  IconButton,
  Stack,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  ButtonGroup,
  Button,
  FormErrorMessage,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  FocusLock,
  Text
} from '@chakra-ui/react'
import { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useSWRConfig } from 'swr';
import { deleteById, updateCheckout } from '../../backend/api/index.js';
import React from 'react';
import { useCallback } from 'react';
import { EditIcon, MinusIcon } from '@chakra-ui/icons';
import AdminFunction from '../AdminFunction.jsx';
import Toast from '../toast.jsx';
import { useForm } from 'react-hook-form'


const Form = ({ onCancel, checkout }) => {

    const { addToast } = Toast();
  
    const {
      trigger: putCheckout,
      error: putError
    } = useSWRMutation('checkouts/update', updateCheckout);
  
    const onSubmit = useCallback(async (data) => {
      const { end_amount } = data;
      await putCheckout({
        id: checkout.id,
        end_amount: end_amount
      });
      if(!putError) {
        addToast('Checkout update', 'Checkout is geupdate', 'success');
      } else {
        addToast('Checkout niet update', 'Checkout is niet geupdate', 'error');
      }
      mutate(`checkouts`)
    }, [ putCheckout , addToast, putError])
  
    const {
      handleSubmit,
      register,
      formState: {errors, isSubmitting},
      onchange,
    } = useForm()
  
    return (
        <Stack spacing={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                    <FormLabel htmlFor={"end_amount"}>Eind bedrag in kassa</FormLabel>
                    <NumberInput defaultValue={checkout.start_amount} step={0.01} type="text"
                        {...register('end_amount', {
                            required: 'This is required'
                        })}
                        onChange={onchange}>
                    <NumberInputField type="text"
                        inputMode="text" 
                        pattern="\d*\.?\d*"  />
                    <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                    </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
                <ButtonGroup display='flex' justifyContent='flex-end' mt={'2'}>
                    <Button variant='outline' onClick={onCancel}>
                    Cancel
                    </Button>
                    <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
                    Save
                    </Button>
                </ButtonGroup>
            </form>
        </Stack>
      )
    }

export default function Checkout({checkout}) {
  const { mutate } = useSWRConfig()
  const { onOpen, onClose, isOpen } = useDisclosure()

  const {
    trigger: deleteCheckout,
  } = useSWRMutation(`checkouts/delete`, deleteById);

  const onDelete = useCallback(async (data) => {
    await deleteCheckout(data);
    mutate(`checkouts`)
  }, deleteCheckout, mutate);

  return (
    <Tr>
        <Td paddingInline={{base:2, md:6}}>
            <HStack justifyContent={'ce'}>
                <Heading fontSize={'sm'} align={'center'}>€ {checkout.start_amount}</Heading>
            </HStack>
        </Td>
        <Td paddingInline={{base:2, md:6}}>
            <HStack>
            <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                closeOnBlur={false}z
                placement="bottom"
                isLazy>
                    <PopoverTrigger>
                    <IconButton size={'sm'} aria-label={'Update einde kassa'} icon={<EditIcon/>} colorScheme='green' isDisabled={checkout.start_amount != checkout.end_amount} />
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                        <FocusLock returnFocus persistentFocus={false}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <Form onCancel={onClose} checkout={checkout} />
                        </FocusLock>
                    </PopoverContent>
                </Popover>
                <Heading fontSize={'sm'} align={'center'}>€ {checkout.end_amount}</Heading>
            </HStack>
        </Td>
        <Td paddingInline={{base:2, md:6}}>
            <HStack justifyContent={'end'}>
                <Heading fontSize={'sm'} align={'center'}> {checkout.end_amount - checkout.start_amount > 0 ? '+' : '-' } € {Math.round((checkout.end_amount - checkout.start_amount) * 100) / 100}</Heading>
                <Text overflow={'hidden'} >({new Date(checkout.date).toLocaleString('nl-BE', { dateStyle: 'short' })})</Text>
            </HStack>
        </Td>
        {/* <Td paddingInline={{base:2, md:6}}>
            <HStack justifyContent={'end'}>
                <Heading fontSize={'sm'} align={'center'}>{new Date(checkout.date).toLocaleString('nl-BE', { dateStyle: 'short' })}</Heading>
            </HStack>
        </Td> */}
        <AdminFunction>
            <Td paddingInline={{base:2, sm:6}}>
                <HStack justifyContent={'end'}>
                    <IconButton size={'sm'} aria-label={'Delete checkout'} icon={<MinusIcon/>} colorScheme='red' onClick={() => onDelete(checkout.id)} />
                </HStack>
            </Td>
        </AdminFunction>
    </Tr>
  )
};