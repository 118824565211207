'use client'

import {
    HStack,
    VStack,
    Flex,
    Container,
    SimpleGrid,
    Text,
    useColorModeValue,
    FormLabel,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Button,
    Stack,
    Input,
    FormControl,
    FormErrorMessage,
    useDisclosure,
    ButtonGroup,
    PopoverTrigger,
    PopoverContent,
    FocusLock,
    PopoverArrow,
    PopoverCloseButton,
    Popover
  } from '@chakra-ui/react'

import { PlusSquareIcon } from "@chakra-ui/icons"
import { useForm } from 'react-hook-form'
import Product from '../components/bar/product.js'
import CreateResetButton from '../components/bar/createResetButton'
import { getAll, save } from '../backend/api/index.js'
import useSWR from 'swr'
import AsyncData from '../components/AsyncData.jsx'
import useSWRMutation from 'swr/mutation'
import { useCallback } from 'react'
import React from 'react';
import Toast from '../components/toast.jsx'

const totaal = 0.00

const Form = ({ onCancel }) => {

  const { addToast } = Toast();

  const {
    trigger: saveProduct,
    error: saveError
  } = useSWRMutation('articles', save);

  const onSubmit = useCallback(async (data) => {
    const { name, price } = data;
    await saveProduct({
      price: price,
      name:name
    });
    if(!saveError) {
      addToast('Product toegevoegd', 'Product is toegevoegd', 'success');
    } else {
      addToast('Product niet toegevoegd', 'Product is niet toegevoegd', 'error');
    }
  }, [ saveProduct , addToast, saveError])

  const {
    handleSubmit,
    register,
    formState: {errors, isSubmitting},
    onchange,
  } = useForm()

    return (
      <Stack spacing={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <FormLabel htmlFor={'naam'}>Naam Product</FormLabel>
            <Input
              id='name'
              placeholder="naam"
              {...register('name', {
                required: 'This is required'
              })}></Input>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor={"prijs"}>Prijs in euro</FormLabel>
            <NumberInput defaultValue={1.00} step={0.01} type="text"
            {...register('price', {
              required: 'This is required'
            })}
            onChange={onchange}>
              <NumberInputField type="text"
              inputMode="text" 
              pattern="\d*\.?\d*"  />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
          <ButtonGroup display='flex' justifyContent='flex-end' mt={'2'}>
            <Button variant='outline' onClick={onCancel}>
              Cancel
            </Button>
            <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
              Save
            </Button>
          </ButtonGroup>
        </form>
      </Stack>
    )
  }


const BarList = () => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const {
        data: articles = [],
        isLoading,
        error,
    } = useSWR('articles', getAll)

    return (
        <Flex
        w={'99%'}
        ml={{base: 0, md: 0}}>
            <Container maxW={'8xl'}>
                <HStack justifyContent={'space-between'} mt={'4'} mb={'4'}>
                    <HStack>
                        <Flex justifyContent="center">
                            <Popover
                                isOpen={isOpen}
                                onOpen={onOpen}
                                onClose={onClose}
                                closeOnBlur={false}
                                placement="bottom"
                                isLazy>
                              <PopoverTrigger>
                                <Button rightIcon={<PlusSquareIcon />} colorScheme="green" w="fit-content">
                                Create
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent p={5}>
                                <FocusLock returnFocus persistentFocus={false}>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <Form onCancel={onClose} />
                                </FocusLock>
                              </PopoverContent>
                            </Popover>
                        </Flex>
                        <CreateResetButton></CreateResetButton>
                    </HStack>
                    <HStack minW={'50'}>
                        <Text
                            color={useColorModeValue('gray.700', 'white')}
                            fontSize={'20'}
                            fontFamily={'body'}
                            id='totaalPrijs'>
                        Totaal: </Text>
                        <Text
                            color={useColorModeValue('gray.700', 'white')}
                            fontSize={'20'}
                            fontFamily={'body'}
                            id='totaalPrijs'>
                        €</Text>
                        <Text
                            id='totaal'
                            color={useColorModeValue('gray.700', 'white')}
                            fontSize={'20'}
                            fontFamily={'body'}>
                        {totaal}</Text>
                    </HStack>
                </HStack>
                <AsyncData loading={isLoading} error={error}>
                    <Flex flexWrap={'wrap'} justifyContent={'center'}>
                      {articles.map((pro) => (
                        <Product naam={pro.name} prijs={pro.price} id={pro.id}></Product>
                      ))}
                    </Flex>
                </AsyncData>
            </Container>
        </Flex>
    )
}

export default BarList