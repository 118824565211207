import { useAuth } from "../contexts/Auth.context";

export default function AdminFunction({
    children
}) {
    const { user } = useAuth();
    if (user.level === 1) {
        return (
            <>
                {children}
            </>
        );
    }
    return null;
}
