'use client'

import { Flex, Text, } from '@chakra-ui/react'
import bg from '../assests/bg.jpg'
import Changelog from '../components/Changelog.jsx'

export default function WithBackgroundImage() {
  return (
    <Flex
      w={'full'}
      h={'100vh'}
    //   overflow="hidden"
      backgroundImage={
        bg
      }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
      padding='6'
      justifyContent={'center'}>
      <Text maxWidth={{ base: 350, md: 1000 }} overflow={'hidden'}>
          <Changelog></Changelog>
      </Text>
    </Flex>
  )
}
