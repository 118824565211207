'use client'

import {
  HStack,
  Heading,
  Flex,
  Container,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  TableContainer
} from '@chakra-ui/react'

import React from "react";
import { getAll } from '../backend/api/index.js';
import Coin from '../components/coins/coin.js'
import useSWR from 'swr';
import AsyncData from '../components/AsyncData.jsx';
import AdminFunction from '../components/AdminFunction.jsx';

const CoinsList = () => {
    const {
        data: coins = [],
        isLoading,
        error,
    } = useSWR('coins', getAll)
    return (
      <Flex w={'99%'} ml={{base: 0, md: 0}}>
          <Container maxW={'8xl'}>
              <HStack>
                    <Heading paddingRight={'8'} paddingBottom={"4"} paddingTop={'4'}>Coins</Heading>
              </HStack>
              <TableContainer w={'100%'}>
              <Table variant='simple' layout={'fixed'}>
                <Thead>
                  <Tr>
                    <Th>Gebruiker</Th>
                    <Th>Aantal</Th>
                    <AdminFunction>
                      <Th></Th>
                    </AdminFunction>
                  </Tr>
                </Thead>
                <Tbody>
                  <AsyncData loading={isLoading}>
                    {coins
                    .sort((a, b) => b.amount - a.amount)
                    .map((coin) => (
                      <Coin coin={coin}></Coin>
                    ))}
                  </AsyncData>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Gebruiker</Th>
                    <Th>Aantal</Th>
                    <AdminFunction>
                      <Th></Th>
                    </AdminFunction>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
        </Container>
    </Flex>
  )
}

export default CoinsList;