import { 
  Input,
  FormLabel,
  Text
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export default function LabelInput({ label, name, type, validationRules, ...rest }) {

  const { register, formState: { errors, isSubmitting } } = useFormContext();

  const hasError = name in errors;

  return (
    <div>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...register(name, validationRules)}
        id={name}
        type={type}
        disabled={isSubmitting}
        isInvalid={hasError}
        className="form-control"
        errorBorderColor='red.500'
        focusBorderColor={hasError ? 'red.500' : 'blue.500'}
        {...rest}
      />
      {hasError ? (
        <Text color='red.500' className="form-text text-danger" data-cy="label_input_error">
          {errors[name].message}
        </Text>
      ) : null}
    </div>
  );
}