import { 
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import useSWRMutation from "swr/mutation";
import { save } from "../backend/api";
import { useCallback } from "react";
import Toast from "./toast";
import { mutate } from "swr";
import React from "react";
import { DeleteIcon } from "@chakra-ui/icons";

function SubmitButton({ children, isLoading, color='green', ...props }) {
  return <Button type="submit" isLoading={isLoading} {...props}>{children}</Button>;
}

export function SubmitButtonAsForm({ children, apiUrl, fetcher=save, fields=null, mutateFields, tekstIsGeluktTitel, tekstIsGefaaldTitel, tekstIsGeluktDesc, tekstIsGefaaldDesc, ...props }) {

  const { trigger, isLoading } = useSWRMutation(apiUrl, fetcher);

  const { addToast } = Toast();

  const { handleSubmit } = useForm();

  const onSubmit = useCallback(async () => {
    try {
      await trigger(fields);
      if(tekstIsGeluktDesc)
        addToast(tekstIsGeluktTitel, tekstIsGeluktDesc, 'success');
      if (mutateFields) {
        mutate(mutateFields);
      }
    } catch (e) {
      if(tekstIsGefaaldDesc)
        addToast(tekstIsGefaaldTitel, tekstIsGefaaldDesc, 'error');
    }
  }, [addToast, trigger, mutateFields, fields, tekstIsGefaaldDesc, tekstIsGeluktDesc, tekstIsGefaaldTitel, tekstIsGeluktTitel]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SubmitButton isLoading={isLoading} children={children} {...props}></SubmitButton>
    </form>
  );
}

function Alert({ isOpen, onClose, cancelRef, ...props }) {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete Event
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <SubmitButtonAsForm {...props}>
              Delete
            </SubmitButtonAsForm>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function DeleteButtonWithAlert({ children, customDelete=false, ...props }) {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  return <>
    {customDelete ? <IconButton icon={<DeleteIcon/>} background={''} color={'red.500'} onClick={onOpen}/> : <IconButton icon={<DeleteIcon/>} colorScheme='transparent' size={'xs'} color={'red.200'} onClick={onOpen}/>}
    <Alert isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} {...props}></Alert>
  </>
  ;
}