'use client'

import {
  Box,
  Center,
  Text,
  Stack,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { deleteById } from '../../backend/api'
import React from 'react'
import AdminFunction from '../AdminFunction'
import { DeleteButtonWithAlert } from '../Buttons'

const Product = ({naam, prijs, id}) => {
  const aantal = 0;
  function updateBar(id){
    var totaalSelected = parseFloat(document.getElementById(id).innerHTML)
    var totaal = parseFloat(document.getElementById('totaal').innerHTML)
    totaal += prijs;
    totaalSelected += 1;
    document.getElementById('totaal').innerHTML = totaal.toFixed(2);
    document.getElementById(id).innerHTML = totaalSelected.toFixed(0);
  }

  return (
    <Center>
      <Box
        height={'110'}
        width={'110'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={2}
        mr={1}
        mb={1}
        onClick={() => updateBar(id)}
        >
        <Stack>
          <HStack spacing={1.5}>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={100}
              fontSize={'sm'}
              letterSpacing={1.1}>
              Aantal:
            </Text>
            <Text
              className='product'
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={100}
              fontSize={'sm'}
              letterSpacing={1.1}
              id={id}>
            {aantal}
            </Text>
            <AdminFunction>
              <DeleteButtonWithAlert
                fetcher={deleteById}
                apiUrl={`articles/delete`}
                fields={id}
                mutateFields={`articles`}
                tekstIsGeluktTitel='Artikel verwijderd'
                tekstIsGefaaldTitel='Artikel verwijderen mislukt'
                tekstIsGeluktDesc='Het artikel is verwijderd'
                tekstIsGefaaldDesc='Er is iets misgelopen bij het verwijderen van het artikel'
                colorScheme='red'
                ml={3}
              >
                <DeleteIcon/>
              </DeleteButtonWithAlert>
            </AdminFunction>
          </HStack>
          <HStack justifyContent={'space-between'}>
            <Text
                // eslint-disable-next-line react-hooks/rules-of-hooks
                color={useColorModeValue('gray.700', 'white')}
                fontSize={'sm'}
                fontFamily={'body'}
                maxW={'80'}
                overflow={'auto'}>
                {naam}
            </Text>
            <Text
                // eslint-disable-next-line react-hooks/rules-of-hooks
                color={useColorModeValue('gray.700', 'white')}
                fontSize={'sm'}
                fontFamily={'body'}>
                {prijs}
            </Text>
          </HStack>
        </Stack>
      </Box>
    </Center>
  )
}
export default Product
